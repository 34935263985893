<template>
  <div>
    <CModal
      :show.sync="$store.state.showModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      <CRow v-if="form">
        <CCol col="12">
          <CCard>
            <CCardBody>
              <CForm>
                <CRow class="mt-3">
                  <CCol sm="12" md="9">
                    <CInput
                      invalid
                      description=""
                      label="الاسم العربي"
                      horizontal
                      autocomplete="name"
                      v-model.trim="form.ar.name"
                      invalid-feedback="يوجد خطأ في هذا الحقل"
                      :is-valid="!$v.form.ar.name.$anyError"
                    />
                  </CCol>
                </CRow>

                <CRow class="mt-3">
                  <CCol sm="12" md="9">
                    <CInput
                      invalid
                      description=""
                      label="الاسم الانجليزي"
                      horizontal
                      autocomplete="name"
                      v-model.trim="form.en.name"
                      invalid-feedback="يوجد خطأ في هذا الحقل"
                      :is-valid="!$v.form.en.name.$anyError"
                    />
                  </CCol>
                </CRow>

                

                <CRow class="mt-5">
                  <CCol sm="12" md="9">
                    <div role="group" class="form-group form-row">
                      <label class="col-form-label col-sm-3">
                        حالة الخدمة
                      </label>
                      <CCol sm="9">
                        <div role="group" class="form-check form-check-inline">
                          <label>
                            <input
                              type="radio"
                              name="service_id"
                              class="form-check-input"
                              value="1"
                              v-model="form.status"
                            />
                            نشط
                          </label>
                        </div>

                        <div role="group" class="form-check form-check-inline">
                          <label>
                            <input
                              type="radio"
                              name="service_id"
                              class="form-check-input"
                              value="0"
                              v-model="form.status"
                            />
                            متوقف
                          </label>
                        </div>
                      </CCol>
                    </div>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <template v-if="form" #header>
        <h6 class="modal-title font-weight-bold">
          تعديل الخدمة رقم : {{ form.id }}
        </h6>
        <CButtonClose @click="closeModal" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="closeModal" color="danger">الغاء</CButton>
        <CButton
          :disabled="loading"
          @click="update"
          type="button"
          color="success"
        >
          <CSpinner v-if="loading" color="white" size="sm" />
          <span v-else> <CIcon name="cil-save" /> حفظ </span>
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";
export default {
  name: "Edit",
  props: ["form"],
  data() {
    return {
      loading: false,
      formErrors: null,
      fileRecords: [],
      fileRecordsForUpload: [],
    };
  },
  validations: {
    form: {
      ar: {
        name: { required, min: minLength(2), max: maxLength(200) },
      },
      en: {
        name: { required, min: minLength(2), max: maxLength(200) },
      },
      status: { required }
    },
  },
  created() {},
  methods: {
    closeModal() {
      this.$store.commit("set", ["showModal", false]);
    },
    update() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;

      this.$http
        .put(`/services/${this.form.id}`, this.form)
        .then((res) => {
          // handle success
          if (res.data.status == 200) {
            this.$emit("updateDone");
            this.$store.commit("set", ["showModal", false]);
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if ((error.response.data.status == 422)) {
            this.formErrors = error.response.data.error.errors;
          }
        });
    },
  },
};
</script>
